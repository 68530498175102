import React from 'react';
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ReactSVG } from "react-svg";

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import LogoFA from '../images/logo-ferragens-aguiar.svg'
import Facebook from '../images/facebook.svg'
import Linkedin from '../images/linkedin.svg'
import Location from '../images/location.svg'
import Phone from '../images/phone.svg'
import InteractionIndication from '../images/interaction-indication.svg'
import DownloadContact from '../images/download-contact.svg'
import DownloadContactMobile from '../images/download-contact-mobile.svg'

import PhoneNumber from '../images/phone-number.svg'
import BtnEmail from '../images/btn-email.svg'
import Whatsapp from '../images/whatsapp.svg'

import OrangeBackground from "../images/bottom-mask.png";
import LeftMask from "../images/left-mask.png";

const NotFoundPage = ({ data }) => {

  const breakpoints = useBreakpoint();

  var socialNetworks = [
    {
      link: "https://www.facebook.com/ferragensaguiaragueda/",
      alt: "Facebook",
      img: Facebook
    },
    {
      link: "https://www.linkedin.com/company/ferragensaguiar/",
      alt: "LinkedIn",
      img: Linkedin
    },
    {
      link: "https://maps.app.goo.gl/PGWU71Y9oP9Wm8B46",
      alt: "Morada",
      img: Location
    },
    {
      link: "tel:+351965557718",
      alt: "Telemóvel",
      img: Phone
    }
  ]

  var contactInfos = [
    {
      link: "https://vasco-pires.ferragensaguiar.com/vcard.vcf",
      alt: "Contacto",
      img: DownloadContact
    },
    {
      link: "tel:+351965557718",
      alt: "Telemóvel",
      img: PhoneNumber
    },
    {
      link: "https://wa.me/351965557718",
      alt: "Whatsapp",
      img: Whatsapp
    },
    {
      link: "mailto:vasco.pires@ferragensaguiar.com",
      alt: "E-mail",
      img: BtnEmail
    }
  ]

  var contactInfosMobile = [

    {
      link: "tel:+351965557718",
      alt: "Telemóvel",
      img: PhoneNumber
    },
    {
      link: "https://wa.me/351965557718",
      alt: "Whatsapp",
      img: Whatsapp
    },
    {
      link: "mailto:vasco.pires@ferragensaguiar.com",
      alt: "E-mail",
      img: BtnEmail
    }
  ]

  const shareContact = () => {
    if (typeof document !== 'undefined' && document !== 'undefined')
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      alert('Windows')
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      alert('Android')
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      alert('iOS')
      // Get the contact information from the website
      var contact = {
        name: "Vasco Rosa Pires",
        phone: "+351 965 557 718",
        email: "vasco.pires@ferragensaguiar.com"
      };
      // create a vcard file
      var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";
      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);

      const newLink = document.createElement('a');
      newLink.download = contact.name + ".vcf";
      newLink.textContent = contact.name;
      newLink.href = url;

      newLink.click();
      return "iOS";
    }
  }



  return (
    <Layout>
      <Seo title="Luís Dias" />
      <div >
        {breakpoints.md ?
          (
            <>
              <div className='container-mobile'>
                <div className="top-div">
                  <div className='content-mobile'>
                    <div className='logos-mobile'>
                      <ReactSVG src={LogoFA} />
                    </div>

                    <div className="social-div-mobile">
                      {socialNetworks.map((social, l) => (
                        <a href={social.link} target="_blank" key={"social" + l}>
                          <ReactSVG src={social.img} />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>


                <div className='middle'>
                  <StaticImage
                    src="../images/middle-mask.png"
                    loading="eager"
                    quality={95}
                    formats={["auto"]}
                    alt=""
                    className='middle-mask'
                  />

                  <div className="photo-mobile">
                    <StaticImage
                      src="../images/luis-dias.png"
                      loading="eager"
                      quality={95}
                      formats={["auto"]}
                      alt=""
                      className="worker-img"
                    />
                  </div>
                </div>

                <div className="bottom-div" style={{ backgroundImage: `url(${OrangeBackground})` }}>
                  <div className='content'>
                    <p className="name-mobile font-prompt-semiBold">
                      {data.globalJson.info.name}
                    </p>
                    <p className="info-mobile font-archivo-light">
                      {data.globalJson.info.department}
                    </p>

                    <div>
                      <a href='https://luis-dias.ferragensaguiar.com/vcard.vcf'>
                        <ReactSVG className="download-contact-mobile" src={DownloadContactMobile} />
                      </a>
                    </div>

                    <div className="bottom-social-div-mobile">
                      {contactInfosMobile.map((info, index) => (
                        info.img !== "DownloadContact" && (
                          <a href={info.link} target="_blank" rel="noopener noreferrer" key={info.link}>
                            <ReactSVG src={info.img} />
                          </a>
                        )
                      ))}
                    </div>
                  </div>
                </div>

                <div className="interaction-indication-mobile-div">
                  <ReactSVG src={InteractionIndication} />
                </div>

              </div>
            </>
          )
          :
          (
            <>
              <div className='container'>
                {/* <StaticImage
                  src="../images/left-mask.png"
                  loading="eager"
                  quality={95}
                  formats={["auto"]}
                  alt=""
                  className='left-mask'
                /> */}
                <div className='left-div' style={{ backgroundImage: `url(${LeftMask})`, backgroundRepeat:'no-repeat', objectFit:'contain' }}>
                  <div className='content'>
                    <p className="name font-prompt-semiBold">
                      {data.globalJson.info.name}
                    </p>
                    <p className="info font-archivo-light">
                      {data.globalJson.info.department}
                    </p>

                    <div className="social-div">
                      {contactInfos.map((info, l) => (
                        <a href={info.link} target="_blank" key={"social" + l}>
                          <ReactSVG src={info.img} />
                        </a>
                      ))}

                    </div>
                  </div>
                </div>

                <div className="right-div">
                  <StaticImage
                    src="../images/right-mask.png"
                    loading="eager"
                    quality={95}
                    formats={["auto"]}
                    alt=""
                    className='right-mask'
                  />

                  <div className='content' style={{ marginTop: '-25vh', marginLeft: '8vw' }}>
                    <div className='logos'>
                      <ReactSVG src={LogoFA} />
                    </div>

                    <div className="social-div">
                      {socialNetworks.map((social, l) => (
                        <a href={social.link} target="_blank" key={"social" + l}>
                          <ReactSVG src={social.img} />
                        </a>
                      ))}
                    </div>

                    <div className="interaction-indication-div">
                      <ReactSVG src={InteractionIndication} />
                    </div>
                  </div>
                </div>

                <div className="photo">
                  <StaticImage
                    src="../images/luis-dias.png"
                    loading="eager"
                    quality={95}
                    formats={["auto"]}
                    alt=""
                  />
                </div>
              </div>
            </>
          )
        }
      </div >
    </Layout >
  )
}

export default NotFoundPage


export const IndexQuery = graphql`
  query notfoundJson {
    globalJson {
      info {
        name
        department
      }
      social {
        link
        alt
        img 
      }
      address {
        locationMobile
        location
        link
      }
    }
  }
`